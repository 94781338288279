import React from 'react'
import { MDXTag } from '@mdx-js/tag'


import Img from "gatsby-image";
import SEO from '../../components/seo';
import ImageLandscape from '../../components/sub/landscape.js';
import PortraitsTwo from '../../components/sub/portraitx2.js';
import Image from '../../components/image';
export const frontmatter = {
  title: "Holiday Planning: New Consumer Expectations & Shopping Trends",
  date: "2017-10-12",
  author: "Lauren Lyons",
  authorTitle: "Content Editor, The Doneger Group",
  teaserImage: "../../../static/media/20171012-0.jpg",
  path: "2017-10-12-Holiday-Planning",
  teaserText: "With the retail industry continuing to experience disruption, its shifting landscape poses an opportunity for retailers to redefine their approach to holiday shopping."
};
const layoutProps = {
  frontmatter
};
export default class MDXContent extends React.Component {
  constructor(props) {
    super(props);
    this.layout = null;
  }

  render() {
    const {
      components,
      ...props
    } = this.props;
    return <MDXTag name="wrapper" components={components}>

      <SEO title="Holiday Planning: New Consumer Expectations & Shopping Trends" description="With the retail industry continuing to experience disruption, its shifting landscape poses an opportunity for retailers to redefine their approach to holiday shopping." author='Lauren Lyons' keywords={['Robots']} />
      <MDXTag name="p" components={components}>{`With the retail industry continuing to experience disruption, its shifting landscape poses an opportunity for retailers to redefine their approach to holiday shopping. Much like the buy-now-wear-now mentality, consumers are no longer held to traditional retail schedules, making a case for brands to reconsider their holiday strategies. With consumers basing their decisions on reviews from other users, price comparison apps and other emerging technologies, there are countless opportunities to test this season. Product tracking has become the norm, with consumers no longer purchasing when needed but waiting to see when and where they’ll receive the best deal. Looking to this emerging behavior, retailers should consider how this may affect how consumers will approach the holiday season this year.`}</MDXTag>
      <ImageLandscape imageSrc="20171012-0.jpg" />
      <MDXTag name="p" components={components}>{`In the age of immediacy, consumers are now able to shop via Alexa and other smart home assistants or by simply browsing Pinterest and Instagram via mobile. With endless options, retailers should consider opening up holiday marketing throughout the year rather than the traditional end-of-year window, as well as promote self-gifting to attract the millennial shopper. With retailers better able to analyze what and when consumers are searching for specific goods and services, savvy brands should look to test new concepts throughout the year.`}</MDXTag>
      <ImageLandscape imageSrc="20171012-1.jpg" />
      <MDXTag name="p" components={components}>{`As we head into this holiday season, these are important ideas to consider.`}</MDXTag>
      <MDXTag name="ul" components={components}>
        <MDXTag name="li" components={components} parentName="ul">{`Consumers are still visiting stores, where most of their inspiration and discovery is still happening, and they’re taking gift ideas from family and friends. Impulse purchasing and self-gifting remains strong, and recommendations from retailers are valued when researching purchases.`}</MDXTag>
        <MDXTag name="li" components={components} parentName="ul">{`Always connected, online reviews and return policies are two deciding elements forming ultimate purchasing decisions. Generationally, Millennials are using Facebook as an inspirational tool, while Gen Z reported Instagram as the site to build their wish list on. Additionally, registries and wish lists are gaining in popularity.`}</MDXTag>
      </MDXTag>
      <ImageLandscape imageSrc="20171012-2.jpg" />
      <MDXTag name="p" components={components}>{`*Looking for convenience, omni-personal and seamless experiences continue to stand out. Blending e-com and brick-and-mortar in exciting and innovative ways, it’s the retailers experimenting with traditional retail concepts that are resonating. From interactive pop-ups and mobile boutiques to Instagram-able spaces, experiential reigns. Retailers who are putting consumers in the designer seat are allowing for a new type of self-expressive consumerism.`}</MDXTag>
      <ImageLandscape imageSrc="20171012-3.jpg" />
      <MDXTag name="ul" components={components}>
        <MDXTag name="li" components={components} parentName="ul">{`Populate favorite products with consumer reviews, giving shoppers the authentic perspective they are looking for.`}</MDXTag>
        <MDXTag name="li" components={components} parentName="ul">{`Use readily-available tech to enhance the overall experience by providing personalized recommendations, or offering apps that can make it easier to locate products in-store.`}</MDXTag>
        <MDXTag name="li" components={components} parentName="ul">{`Influence purchasing decisions by offering limited-time sales, the ability to buy online and pick up in-store or even curbside, free gift with purchase and helpful consumer service when needed.`}</MDXTag>
        <MDXTag name="li" components={components} parentName="ul">{`Eliminate frustrations where possible, offering free return shipping, a full refund over store credit and allowing for e-com purchases to be returned in-store.`}</MDXTag>
      </MDXTag>
           </MDXTag>;
  }

}
export const _frontmatter = {};
    